<template>
	<div id="reserve-order">
		<Header2022 :navbg='true'></Header2022>
		<div class="reserve" v-if="houseInformation">
			<div class="left-wrap">
				<div class="price-wrap">
					<i class="total">{{houseInformation.futurePrices |toMoney}} 起</i>
					 <i class="separator">|</i>
					<i class="unit-price">{{houseInformation.unitPrice | toMoney}}/m2 起</i>
					<i class="total">了解详情</i>
				</div>
				<img :src="houseInformation.project_image">
			</div>
			<div class="reserve-wrap">
				<div class="change" @click="changeConfigTap">
					<i class="el-icon-arrow-up"></i>
					更改配置
				</div>
				<div class="house-information">
					<div class="title">您的 订制房屋</div>
					<div class="name">{{houseInformation.house_name}}</div>
					<div class="specifications">{{houseInformation.house_another}}</div>
					<div class="area">{{houseInformation.project_name}} {{houseInformation.project_address}}</div>
					<div class="options-list">
						<div class="options-item" v-for="(item,index) in houseConfig" :key="index">
							<div class="options-name">{{item.option_value_name}}</div>
							<div class="price">{{item.price|toMoney}}</div>
						</div>
						<div class="options-item order-price">
							<div class="options-name">房屋价格</div>
							<div class="price">{{houseInformation.futurePrices|toMoney}}</div>
						</div>
						<div class="options-item order-price">
							<div class="options-name">产品总价</div>
							<div class="price">{{totalPrice|toMoney}}</div>
						</div>
					</div>
					<div class="prompt">
						*本户型图所标尺寸仅供参考，交房尺寸以合同约定为准；相同户型单位因楼栋、楼层、单元等差别，局部结构、面积等可能不同。
					</div>
				</div>
				<div class="house-information">
					<div class="title">购买信息</div>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
						label-position="left">
						<el-form-item label="购买人姓名" prop="name">
							<el-input v-model="ruleForm.name"></el-input>
						</el-form-item>
						<el-form-item label="证件类型" prop="ID">
							<el-select v-model="ruleForm.ID" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="证件号码" prop="idNumber">
							<el-input v-model="ruleForm.idNumber"></el-input>
						</el-form-item>
						<el-form-item label="手机" prop="telephone">
							<el-input v-model="ruleForm.telephone"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="house-information">
					<div class="title">预定方式</div>
					<div class="options-item">
						<div class="options-name">意向金：</div>
						<div class="price">{{intentionPrice}}</div>
					</div>
					<div class="prompt">
						该一项仅作为小院房屋预订诚意金使用。您可以凭借意向金订单作为您优先选房的依据。此意向金缴纳的编号顺序仅为凭证使用，不作为最终选房顺序，选房规则以开放销售期定制的规则为准。
					</div>
					<div class="protocol" @click="intentTap">
						<i></i>
						<i class="pact">提交订单即视为同意《小院产品订制协议》</i>
					</div>
					<div class="type-btn" @click="reserveTap('ruleForm')">支付意向金{{intentionPrice}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header2022 from '@/components/header2022.vue'
	import {
		mapState
	} from 'vuex'
	import {
		getCode,
		wxPayment
	} from '@/common/app.js';
	export default {
		components: {
			Header2022,
		},
		data() {
			return {
				options: [{
					value: '1',
					label: '居民身份证'
				}, {
					value: '2',
					label: '护照'
				}, {
					value: '3',
					label: '军官证'
				}, {
					value: '4',
					label: '台胞证'
				}, {
					value: '5',
					label: '香港身份证'
				}, {
					value: '6',
					label: '澳门居民身份证'
				}],
				ruleForm: {
					name: '',
					idNumber: '',
					telephone: '',
					ID: '',
				},
				rules: {
					name: [{
							required: true,
							message: '请输入购买人姓名',
							trigger: 'blur'
						},
						{
							max: 50,
							message: '名字不能超过50个字符',
							trigger: 'blur'
						}
					],
					ID: [{
						required: true,
						message: '请选择证件类型',
						trigger: 'change'
					}],
					idNumber: [{
						required: true,
						message: '请输入证件号码',
						trigger: 'blur'
					}, ],
					telephone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'change'
						},
						{
							max: 11,
							message: '手机不能超过11个字符',
							trigger: 'blur'
						}
					],
				}
			};
		},
		filters: {
			toMoney(val) {
				if (val)
					return new Intl.NumberFormat('zh', {
						style: 'currency',
						currency: 'CNY'
					}).format(val);
			},
		},
		computed: {
			...mapState(['houseApi', 'houseConfig', 'houseInformation', 'user_info', 'ocApi']),
			totalPrice() {
				return this.$store.getters.houseTotalPrice;
			},
			intentionPrice() {
				return utils.toMoney(this.houseInformation.intentionPrice);
			},
			configPrice() {
				let configPrice = 0;
				if (this.houseConfig) {
					for (const key in this.houseConfig) {
						if (this.houseConfig.hasOwnProperty(key)) {
							const element = this.houseConfig[key];
							configPrice = utils.fixedFloat(configPrice, parseFloat(element.price), '+');
						}
					}
				}
				return configPrice;
			},
		},
		created() {
			if (this.user_info) {
				this.ruleForm.name = this.user_info.fullname;
				this.ruleForm.idNumber = this.user_info.id_card;
				this.ruleForm.telephone = this.user_info.telephone;
			}
			this.getAgreement();
		},
		methods: {
			changeConfigTap() {
				this.$router.back();
			},
			reserveTap: utils.debounce(
				async function(formName) {
					// this.$router.replace({
					// 	name: 'weixinPay',
					// 	params: {
					// 		id:2,
					// 		code: escape(2)
					// 	}
					// })
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.createHouseCarts();
						}
					});
					},
					500,
					true
			),
			getAgreement: function() {
				this.$axios.get(this.ocApi + 'book/banner?name=house_reserve_agreement')
					.then(res => {
						if (res.data.data.status == 1) {
							this.pdf = res.data.data.images[0].link;
						} else {
							this.$message.error(res.data.message);
						}
					})
			},
			createHouseCarts: async function() {
				let isLogin = await this.$store.dispatch('isLogin');
				if (!isLogin) {
					this.$message.error('请登录后支付！');
					this.$router.replace({
						name: 'login',
						query: {
							redirectName: 'reserveOrder'
						}
					});
					return false;
				}
				var option = {};
				for (const key in this.houseConfig) {
					if (this.houseConfig.hasOwnProperty(key)) {
						const element = this.houseConfig[key];
						option[element.product_option_id] = element.product_option_value_id;
					}
				}
				this.$axios.post(this.houseApi + 'pc/house/carts', {
						type: 1,
						option,
						name: this.ruleForm.name,
						telephone: this.user_info.telephone, //当前登录人电话
						house_telephone: this.ruleForm.telephone, //输入的电话
						document_type: this.ruleForm.ID,
						document_code: this.ruleForm.idNumber,
						city: this.houseInformation.project_address,
						house_price: parseFloat(this.houseInformation.futurePrices),
						match_price: parseFloat(this.configPrice),
						total: this.totalPrice,
						product_id: this.houseInformation.project_id,
						quantity: 1,
					})
					.then(res => {
						if (res.data.status == 1) {
							this.$router.replace({
								name: 'weixinPay',
								params: {
									id: res.data.data.paycode.order_id,
									code: escape(res.data.data.paycode.payment_params.code_url)
								}
							})
							
						} else {
							this.$message.error(res.data.message);
						}
					})
			},
			intentTap() {
				window.open(this.pdf);
			},
		}
	}
</script>

<style lang="scss">
	#reserve-order {
		color: #000000;
		font-size: 16px;
		overflow: hidden;

		.el-input__inner {
			border-radius: 20px;
			background-color: #F4F4F4;
			border: none;
		}

		.el-select {
			width: 100%;
		}

		.reserve {
			width: 100%;
			display: flex;
			height: calc(100vh - 60px);
			margin-top: 60px;

			.left-wrap {
				width: 78.3%;
				height:100%;
				position: relative;
				img {
					width: 100%;
					height: 100%;
				}
				.price-wrap{
					background: #F4F4F4;
					border-radius: 10px 10px 0px 0px;
					position: absolute;
					bottom: 0;
					left: 25%;
					font-size: 20px;
					height: 72px;
					line-height:72px;
					padding: 0 28px;
					.total{
						font-weight: 400;
						background: linear-gradient(10deg, #00C9FF 0%, #11ED84 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					.unit-price{
						font-weight: 300;
						color: #B9B9B9;
						margin-right: 24px;
					}
					.separator{
						color:#B9B9B9;
						margin: 0 17px;
					}
				}
			}

			.reserve-wrap {
				flex: 1;
				min-width: 416px;
				padding: 125px 25px 0;
				box-sizing: border-box;
				height: 100vh;
				overflow-y: scroll;

				.change {
					font-weight: 200;
					color: #393C41;
					margin-bottom: 59px;
				}

				.title {
					font-size: 28px;
					font-weight: 800;
					line-height: 28px;
					margin: 59px 0 28px;
				}

				.name {
					font-size: 22px;
					font-weight: bold;
					line-height: 22px;
					margin-top: 22px;
				}

				.specifications {
					font-weight: 200;
					margin: 7px 0 13px;
				}

				.area {
					font-size: 20px;
					font-weight: bold;
					line-height: 20px;
				}

				.options-list {
					font-weight: 300;
					margin: 51px 15px 28px;

					.order-price {
						border-top: 2px solid #393C41;
						line-height: 46px;
					}
				}

				.options-item {
					display: flex;
					line-height: 26px;
					justify-content: space-between;
				}

				.prompt {
					margin-top: 17px;
					font-size: 14px;
					font-weight: 300;
					padding: 15px 18px 23px 29px;
					line-height: 18px;
					border: 2px solid #00C9FF;
					border-radius: 10px;
				}

				.house-information {
					text-align: center;
				}

				.input-item {
					font-size: 18px;
					line-height: 18px;
					font-weight: 400;
					text-align: left;
					margin-bottom: 35px;

					.label {
						margin-bottom: 14px;
					}
				}

				.protocol {
					font-size: 14px;
					color: #7d8091;
					width: 100%;
					text-align: center;
					margin: 88px 0 30px;

					.pact {
						background: linear-gradient(0deg, #00C9FF 0%, #11ED84 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}

				.type-btn {
					height: 40px;
					font-size: 14px;
					border-radius: 20px;
					line-height: 40px;
					width: 100%;
					text-align: center;
					background: linear-gradient(16deg, #00C9FF 0%, #11ED84 100%);
					color: #FFFFFF;
					margin-bottom: 451px;
				}
			}
		}
	}
</style>
